import * as React from 'react';
import {useState} from 'react';

export const AppContext = React.createContext({});

const Provider = (props: any) => {
    const [isDark, setTheme,] = useState(false);

    return (
        <AppContext.Provider
            value={{
                isDark,
                changeTheme: () => {
                    return setTheme(!isDark);
                },
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default Provider;
