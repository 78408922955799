import './src/styles/normalize.css';
import './src/styles/variables.css';
import './src/styles/global.css';
import './src/styles/grid.css';

import * as React from 'react';

// TODO: this should be moved to DatePicker.tsx (it should be possible to use CSS modules according to the module docs.
import './src/styles/react-datepicker.css';
// TODO: this should be moved to DatePicker.tsx (it should be possible to use CSS modules according to the module docs.
import './src/styles/react-datepicker.css';
import Provider from './src/store/provider';

const wrapRootElement = ({ element, }) => {
    return (
        <>
            <Provider store={{}}>
                {element}
            </Provider>
        </>
    );
};

const onInitialClientRender = () => {
    window.resizeTimeout = null;
    window.addEventListener('resize', () => {
        clearTimeout(window.resizeTimeout);
        window.resizeTimeout = setTimeout(() => {
            document.documentElement.style.setProperty('--page-scrollbar-width', window.innerWidth - document.documentElement.clientWidth + 'px');
        }, 500);
    });
};

const onRouteUpdate = ({ location, prevLocation }) => {
    setTimeout(() => {
        document.documentElement.style.setProperty('--page-scrollbar-width', `${window.innerWidth - document.documentElement.clientWidth}px`);
    }, 0);
}

export {wrapRootElement, onRouteUpdate, onInitialClientRender};
